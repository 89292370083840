'use strict';

var progress = require('./progress'),
    util = require('./util'),
    bonusProductsView = require('./bonus-products-view'),
    Promos = require('./promos');

var minicart = {
    $el: $('#mini-cart'),
    init: function () {
        this.$content = this.$el.find('.mini-cart-content');
        this.width = this.$content.width();
        this.largeViewport = util.breakpoints.xl + (2 * this.width);
        Promos.addPromoMessages();

        // events
        /**
         * @listener
         * @desc Listens for the click event on the mini cart icon and opens/closes the mini cart content
         */
        $('.mini-cart-total').on('click.minicart', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (!this.$content.hasClass('mini-cart-show')) {
                $('body').addClass('navbar--opened');
                this.slide();
            } else {
                this.close();
            }
        }.bind(this));

        /**
         * @listener
         * @desc Listens for the click event on the mini cart close button and closes the mini cart when triggered
         */
        $('.mini-cart-close-btn, .mini-cart-close').on('click.minicart', function() {
            this.close();
            $('body').removeClass('navbar--opened');
        }.bind(this));

        /**
         * @listener
         * @desc Listens for the click event on the product line item remove icon
         */
        $('.remove-mini-cart-product').on('click.minicart', function (e) {
            const $product = $(e.target).parents('.mini-cart-product');
            const uuid = $product.attr('data-item-uuid');
            const pid = $product.attr('data-pid');

            this.removeProduct(uuid, pid, true);
        }.bind(this));

        /**
         * @listener
         * @desc Listens for the click event on the find replacement button
         */
        $('.mini-cart-find-replacement a').on('click', function (e) {
            e.preventDefault();
            const searchURL = $(e.target).get(0).href;
            const $product = $(e.target).parents('.mini-cart-product');
            const uuid = $product.attr('data-item-uuid');
            const pid = $product.attr('data-pid');

            const jqXHR = this.removeProduct(uuid, pid);
            jqXHR.done(function () {
                window.location = searchURL;
            });
        }.bind(this));

        /**
         * @listener
         * @desc Listens for the transitionend event and makes sure more transitions don't occur on the mini cart
         */
        // $('.mini-cart-content').on('transitionend', function () {
        //     $(this).addClass('mini-cart-no-trans');
        // });

        /**
         * @listener
         * @desc Listens for the click event on the mini order totals toggle icon
         */
        $('.mini-order-totals-toggle').on('click', function () {
            $(this).toggleClass('active');
            $('.mini-order-totals').slideToggle();
        });

        /**
         * @listener
         * @description Allow the user to exit out of the mini cart via keyboard via the escape key
         */
        $('.mini-cart-link').on('keyup', function(e) {
            if (e.keyCode === 27) {
                this.close();
            } else if (e.keyCode === 13){
                this.slide();
            }
        }.bind(this));

        /**
         * @listener
         * @desc Listens for the basket update event and refreshes the basket if the change is from anywhere but the mini cart
         */
        $(document).off('basketupdate.minicart').on('basketupdate.minicart', function (e) {
            if (e.source === 'tile') {
                const jqXHR = $.ajax({
                    url: window.Urls.miniCart,
                    data: {
                        cartChanged: true,
                        miniCartAlter: false
                    }
                });

                jqXHR.done(function (html) {
                    this.show(html, e.suppress, true);
                }.bind(this));
            }
        }.bind(this));

        /**
         * @listener
         * @desc Listens for window resize events and repositions the mini cart based on the current viewport
         */
        $(window).off('resize.minicart').on('resize.minicart', function () {
            this.setPosition();
        }.bind(this));

        // Set the mini cart's position
        this.setPosition();
    },

    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     * @param {Boolean} Determines if the mini cart should be exposed or not
     */
    show: function (html, suppressSlide, comeFromAdd) {
        var isMinicartOpen = $('.mini-cart-show').length
        this.$el.html(html);
        this.init();
        Promos.addPromoMessages();
        // console.log('final supress', this)
        if (!suppressSlide && !isMinicartOpen && util.mediaBreakpointUp('xl') && !comeFromAdd) {
            this.slide();
            $('.mini-cart-close-btn, .mini-cart-close').focus();
        } else {
            if (($('.mini-cart-empty').length && util.mediaBreakpointUp('xl') && !comeFromAdd) || (isMinicartOpen)) {
                this.$content.addClass('mini-cart-no-trans');
                this.$content.addClass('mini-cart-show');
            }
        }

        bonusProductsView.loadBonusOption();
/*
ToDo: Add Route for SFRA

        // Reload the navigation customer info based on the new cart
        const ajax = require('./ajax');
        ajax.load({
            url: window.Urls.navCustomerInfo,
            target: $('.nav-customer-info-section')
        });
*/
    },

    /**
     * @function
     * @description Slides the contents of the mini cart into view
     */
    slide: function () {
        // show the item
        this.$content.removeClass('mini-cart-no-trans');
        this.$content.addClass('mini-cart-show');
        this.setPosition();
        $('.mini-cart-link').attr('aria-expanded', 'true');
    },

    /**
     * @function
     * @description Closes the mini cart
     */
    close: function () {
        this.$content.removeClass('mini-cart-no-trans');
        this.$content.removeClass('mini-cart-show').removeAttr('style');
        $('.mini-cart-link').attr('aria-expanded', 'false');
    },

    /**
     * @function
     * @desc Sets the mini cart's position based on the current viewport
     * @returns
     */
    setPosition: function () {
        if ($(window).width() < this.largeViewport) {
            this.$content.removeAttr('style');
        } else if (this.$content.hasClass('mini-cart-show')) {
            const viewportWidth = $(window).width();
            const mainWidth = util.breakpoints.xl;
            const rightPosition = ((viewportWidth - mainWidth) / 2) - this.width;
            this.$content.css('right', rightPosition);
        }
    },

    updateQuantity: function(e) {
        var $product = $(e.target).parents('.quantity-container');
        var uuid = $product.attr('data-item-uuid');
        var pid = $product.attr('data-product') || $product.attr('data-itemid') || $product.attr('data-pid');
        var quantity = $(e.target).val();
        var productjqXHR = $.ajax({
            method: 'get',
            url: window.Urls.updateLineItemQuantity,
            data: {
                uuid: uuid,
                pid: pid,
                quantity: quantity,
                miniCartAlter: $(e.target).parents('.mini-cart-content').length > 0 || $('.mini-cart-content').hasClass('mini-cart-show')
            }
        })


        progress.show('.mini-cart-content', false);

        productjqXHR.done(function (html) {
            if ($('.page').data('action') === 'Cart-Show') {
                location.reload();
                return;
            }
            if ($('#mini-cart').length) {
                this.show(html,$(e.target).parents('.promo-modal').length > 0 || $(e.target).parents('.card').length > 0 || !$('.mini-cart-empty').length, true);
            } else {
                const transientUpdates = $(html).closest('script');
                $('body').find('.transient-resources').replaceWith(transientUpdates);
                Promos.addPromoMessages();
            }

                // Trigger a mini cart update event for other parts of the site to listen for
            $(document).trigger({
                type: 'basketupdate',
                pid: pid,
                quantity: quantity,
                source: 'minicart',
                uuid: uuid
            });

            $(document).trigger({
                type: 'basketremove',
                uuid: uuid,
                pid: pid,
                source: 'minicart'
            });


        }.bind(this))

        productjqXHR.always(function () {
            progress.hide();
        })

        return productjqXHR;
    },

    /**
     * @function
     * @desc Removes a product from the mini cart
     */
    removeProduct: function (uuid, pid, miniCartAlter) {
        const jqXHR = $.ajax({
            method: 'get',
            url: window.Urls.removeLineItem,
            data: {
                uuid: uuid,
                pid: pid,
                miniCartAlter: miniCartAlter || false
            }
        });

        // Show the progress indicator over the mini cart content
        progress.show(this.$content,false);

        jqXHR.done(function (html) {
            if ($('.page').data('action') === 'Cart-Show') {
                location.reload();
                return;
            }
            if ($('#mini-cart').length) {
                this.show(html, true, true);
            } else {
                const transientUpdates = $(html).closest('script');
                $('body').find('.transient-resources').replaceWith(transientUpdates);
                Promos.addPromoMessages();
            }

            $(document).trigger({
                type: 'basketremove',
                uuid: uuid,
                pid: pid,
                source: 'minicart'
            });

            $(document).trigger({
                type: 'basketupdate',
                uuid: uuid,
                pid: pid,
                quantity: 0,
                source: 'minicart',
                suppress: true
            });
           // $('body').removeClass('navbar--opened');
        }.bind(this));

        // Make sure to remove the progress indicator after the Ajax call
        jqXHR.always(function () {
            progress.hide();
        });

        return jqXHR;
    }
};

module.exports = minicart;
