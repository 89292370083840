'use strict';

const ajax = require('grocery/ajax'),
    dialog = require('grocery/dialog'),
    minicart = require('grocery/minicart'),
    productTile = require('grocery/product-tile'),
    progress = require('grocery/progress'),
    util = require('grocery/util'),
    validator = require('grocery/validator');

var delivery = {
    init: function () {
        initializeEvents();
        initializeDocument();
        initializeMiniCart();
        initializeCart();
        initializeCheckout();
    },
    refreshProductPrices: refreshProductPrices
};
var orderEditExpirationCountdown;

/**
 * @function
 * @desc Initializes the delivery events
 */
function initializeEvents() {
    if (!$('.minibanner-label').text()){
        $('.minibanner').hide();
    }

    var $container = $('.delivery-home-container');

    $('#homepage-postcode-input').on('change click mousedown keydown', function(){
        $('#minicart-postcode-error').hide();
    });

    /**
     * @listener
     * @desc Listens for the submit event on the postcode checker form
     */
    $container.find('.check-postcode')
        .off('submit.delivery')
        .on('submit.delivery', function (e) {
            e.preventDefault();
            const $form = $(this);
            const jqXHR = checkPostcode($form);

            if (typeof jqXHR !== 'undefined') {
                jqXHR.done(function (response) {
                    if (typeof response === 'object' &&
                        response.data !== null) {
                        refreshHomeDeliveryTemplate($container);
                        refreshProductPrices(true);
                    } else {
                        displayServerError();
                    }
                    window.location.reload();
                });
            }
        });

    /**
     * @listener
     * @desc Listens for the click event on the change postal code button
     */
    $container.find('.change-postal-code')
        .off('click.delivery')
        .on('click.delivery', function (e) {
            e.preventDefault();
            const $link = $(this);

            const jqXHR = $.ajax({
                type: 'POST',
                url: $link.attr('href')
            });

            jqXHR
                .done(function (response) {
                    if (typeof response === 'object' &&
                        response.data !== null) {
                        refreshHomeDeliveryTemplate($container);
                        // window.User.zip = null;
                        $('.tile-add-to-cart').addClass(
                            'delivery-add-to-cart').removeClass(
                            'tile-add-to-cart');
                        $('.add-to-cart').addClass(
                                'details-delivery-add-to-cart')
                            .removeClass('add-to-cart');
                    } else {
                        displayServerError();
                    }
                });

            jqXHR.fail(function () {
                displayServerError();
            });
        });

    /**
     * @listener
     * @desc Listens for the click event on the rebook link
     */
    $container.find('.rebook-slot')
        .on('submit', function (e) {
            e.preventDefault();
            const $form = $(this);

            if ($form.valid()) {
                const jqXHR = $.ajax({
                    type: 'POST',
                    url: $form.attr('action'),
                    data: $form.serialize()
                });

                jqXHR.done(function (response) {
                    if (typeof response === 'object' &&
                        typeof response.data !== 'undefined') {
                        refreshHomeDeliveryTemplate($container);
                    } else {
                        showDeliverySlotUnavailable($container,
                            window.Urls.headerPostcodeValidation);
                    }
                });

                jqXHR.fail(function () {
                    displayServerError();
                });
            }
        });

    /**
     * @listener
     * @desc Listens for the click event on the delivery details section in the
     *       header
     */
    $('body')
        .off('click.delivery')
        .on('click.delivery', '.header-delivery-details, .delivery-status-close-button, .continue-browsing-button, .continue-browsing-button', function (e) {
            e.stopPropagation();
            var urlParams;
            var is7023 = $(this).hasClass('delivery-7023');
            var is7024 = $(this).hasClass('delivery-7024');
            if (is7023){
                urlParams = {
                    methodID: '7023'
                };
            }
            if (is7024){
                urlParams = {
                    methodID: '7024'
                };
            }
            $('.postcode-validation-form').spinner().start();
            $.ajax({
                url: window.Urls.selectShippingMethods,
                type: 'post',
                dataType: 'json',
                data: urlParams,
                success: function () {
                    $('.postcode-validation-form').spinner().stop();
                },
                error: function () {
                    $('.postcode-validation-form').spinner().stop();
                }
            });
            if ($(e.target).parents('.delivery-details').length === 0 ||
                $(e.target).closest(
                    '.delivery-status-close-button').length ||
                $(e.target).closest(
                    '.continue-browsing-button').length) {
                let $dropdown = $(this).find('.delivery-details');

                if (!$dropdown.length) {
                    $dropdown = $(this)
                        .closest('.delivery-details');
                }

                if ($dropdown.children().length > 0) {
                    if (!$(this).parents(
                            '.delivery-popover-container').find(
                            '.check-postcode').length) {
                        if ($(this).closest('.product-tile').find(
                                '.tile-add-to-cart').length) {
                            productTile.addToCart($(this).closest(
                                '.product-tile'));
                            $(this).closest('.product-tile').find(
                                '.tile-add-to-cart').off(
                                'click.delivery');
                        } else if ($(this).closest(
                                '.product-detail').find(
                                '#add-to-cart').is(
                                ':not(:disabled)')) {

                            const $addToCartButton = $(this)
                                .closest('.product-detail')
                                .find('#add-to-cart');
                            $addToCartButton
                                .addClass('add-to-cart')
                                .removeClass(
                                    'details-delivery-add-to-cart');

                            $addToCartButton.off('click.delivery');
                            $addToCartButton.trigger('click');
                        }
                    }

                    $dropdown.slideUp();
                    $dropdown.empty();
                    $('.modal-background').hide();
                } else {
                    $dropdown.each(function () {
                        $(this).empty();
                    });
                    const jqXHR = refreshDropDownDeliveryTemplate($dropdown);
                    jqXHR.done(function (response) {
                        if (response !== null) {
                            $dropdown.slideDown();
                        }
                    });
                }
            }
        });

    /**
     * @listener
     * @desc Listens for the click event on the add to cart button in the
     *       product tile
     */
    $('body')
        .off('click.deliveryadd')
        .on('click.deliveryadd', '.delivery-add-to-cart', function (e) {
            e.preventDefault();
            const productTile = $(this).parents('.product-tile');
            const $dropdown = productTile.find('.delivery-details');

            // Check class in case of ajax update.
            if ($(this).hasClass('delivery-add-to-cart')) {
                showAddToCartDropDown($dropdown);
                $('#dropdown-postcode-input').mask('00000-000');
            }
        });

    /**
     * @listener
     * @desc Listens for the click event on modal overlay to close
     *       delivery modal
     */
    $('body')
        .off('click.deliveryclose')
        .on('click.deliveryclose', '.modal-background', function (e) {
            e.preventDefault();

            // Close the visible delivery details modal
            $('.delivery-details:visible').slideUp();
            $('.delivery-details:visible').empty();
            refreshProductPrices(false);
            $('.modal-background').hide();
        });

    /**
     * @listener
     * @desc Listens for the click event on the add to cart button on the PDP
     */
    $('body')
        .off('click.deliverydetails')
        .on('click.deliverydetails', '.details-delivery-add-to-cart', function (e) {
            e.preventDefault();
            const $addToCartSection = $(this).parents('.product-qty-a2c');
            const $dropdown = $addToCartSection.find('.delivery-details');

            // Check class in case of ajax update.
            if ($(this).hasClass('details-delivery-add-to-cart')) {
                showAddToCartDropDown($dropdown);
                $('#dropdown-postcode-input').mask('00000-000');
            }
        });
}

/**
 * @function
 * @desc Initializes the drop down delivery section and it's events
 * @param $dropdown
 * @returns
 */
function initializeDeliveryDropdown($dropdown) {
    validator.initForm($dropdown.find('form'));

    /**
     * @listener
     * @desc Listens for the submit event on the post code form to update the
     *       post code
     */
    $dropdown.find('.check-postcode')
        .off('submit.delivery')
        .on('submit.delivery', function (e) {
            e.preventDefault();
            const $form = $(this);
            const jqXHR = checkPostcode($form);

            if (typeof jqXHR !== 'undefined') {
                jqXHR.done(function (response) {
                    if (typeof response === 'object' &&
                        typeof response.data !== 'undefined') {
                        if (response.data.code === 'success' &&
                            $form
                            .closest('.product-tile').length) {
                            $(
                                    '.product-tile .delivery-add-to-cart')
                                .addClass(
                                    'tile-add-to-cart')
                                .removeClass(
                                    'delivery-add-to-cart');
                            $('.details-delivery-add-to-cart').addClass('add-to-cart')
                                    .removeClass('details-delivery-add-to-cart');
                        }

                        refreshDropDownDeliveryTemplate($dropdown);

                        // Refresh the homepage delivery
                        // options if they are present
                        if ($('#delivery-options-display').length) {
                            refreshHomeDeliveryTemplate($('#delivery-options-display'));
                        }
                    } else {
                        displayServerError();
                    }
                });
            }
        });

    /**
     * @listener
     * @desc Listens for the click event on the book delivery button and adds
     *       the product to the cart if conditions are met
     */
    $dropdown.find('.book-delivery-button')
        .off('click.delivery')
        .on('click.delivery', function (e) {
            e.preventDefault();
            const href = this.href;

            if ($(this).closest('.product-tile').length) {
                const jqXHR = productTile.addToCart($(this).closest(
                    '.product-tile'), true);
                jqXHR.done(function () {
                    window.location = href;
                });
            } else if ($(this).closest('.product-detail').find('#add-to-cart').is(':not(:disabled)')) {
                const $addToCartButton = $(this).closest('.product-detail')
                    .find('#add-to-cart');
                $addToCartButton.addClass('add-to-cart').removeClass(
                    'details-delivery-add-to-cart');
                $addToCartButton.off('click.delivery');
                $(document).on('add-to-cart-pdp', function () {
                    window.location = href;
                });
                $addToCartButton.trigger('click');
            } else {
                window.location = href;
            }
        });

    /**
     * @listener
     * @desc Listens for the click event on the post code change and delivery
     *       back buttons to bring back the post code form
     */
    $dropdown.find('.change-postal-code, .delivery-back')
        .off('click.delivery')
        .on('click.delivery', function (e) {
            e.preventDefault();
            var $link = $(this);
            $.ajax({
                type: 'POST',
                url: $link.attr('href'),
                success: function (response) {
                    if (typeof response === 'object' &&
                        typeof response.data !== 'undefined') {
                        refreshDropDownDeliveryTemplate($dropdown);

                        window.User.zip = null;
                        $('.tile-add-to-cart').addClass(
                                'delivery-add-to-cart')
                            .removeClass(
                                'tile-add-to-cart');
                        $('.add-to-cart')
                            .addClass(
                                'details-delivery-add-to-cart')
                            .removeClass('add-to-cart');

                        // Refresh the homepage delivery
                        // options if they are present
                        if ($('#delivery-options-display').length) {
                            refreshHomeDeliveryTemplate($('#delivery-options-display'));
                        }
                    } else {
                        displayServerError();
                    }
                },
                error: function () {
                    displayServerError();
                }
            });
        });

    /**
     * @listener
     * @desc Listens for the submit event on the slot rebooking form
     */
    $dropdown.find('.rebook-slot')
        .on('submit', function (e) {
            e.preventDefault();
            var $form = $(this);
            if ($form.valid()) {
                $.ajax({
                    type: 'POST',
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    success: function (response) {
                        if (typeof response === 'object' &&
                            typeof response.data !== 'undefined') {
                            refreshDropDownDeliveryTemplate($dropdown);
                        } else {
                            displayServerError();
                        }
                    },
                    error: function () {
                        displayServerError();
                    }
                });
            }
        });

    /**
     * @listener
     * @desc Listens for the input on the postcode field
     */
    $dropdown.find('input[name="postcode"]').on('change keyup', function () {
        $(this).val($(this).val().toUpperCase());
    });

    // Remove any the old dropdown expiration countdown
    if (typeof orderEditExpirationCountdown === 'number') {
        clearInterval(orderEditExpirationCountdown);
    }
    var $orderDeliveryExpirationMessage = $dropdown
        .find('.delivery-expiration-message');
    const expirationDateTime = $orderDeliveryExpirationMessage
        .attr('data-expiration-date');
    if (typeof expirationDateTime !== 'undefined' &&
        expirationDateTime !== 'null') {
        orderEditExpirationCountdown = setOrderEditExpirationCountdown($orderDeliveryExpirationMessage);
    }
}

/**
 * @function
 * @desc Sets up the mini cart delivery functionality
 * @returns
 */
function initializeMiniCart() {
    const $miniCartContent = $('#mini-cart, .left-nav-minicart');

    /**
     * @listener
     * @desc Listens for the submit event on the post code form to update the
     *       post code
     */
    $miniCartContent
        .off('submit.delivery')
        .on('submit.delivery', '.check-postcode', function (e) {
            e.preventDefault();
            const $form = $(this);
            const jqXHR = checkPostcode($form);

            if (typeof jqXHR !== 'undefined') {
                jqXHR.done(function (response) {
                    if (typeof response === 'object' &&
                        typeof response.data !== 'undefined') {
                        if (response.data.code === 'success' &&
                            $form
                            .closest('.product-tile').length) {
                            $(
                                    '.product-tile .delivery-add-to-cart')
                                .addClass(
                                    'tile-add-to-cart')
                                .removeClass(
                                    'delivery-add-to-cart');
                        }

                        refreshMiniCartTemplate($miniCartContent
                            .find('.mini-cart-delivery-states'));

                        // Refresh the homepage delivery
                        // options if they are present
                        if ($('#delivery-options-display').length) {
                            refreshHomeDeliveryTemplate($('#delivery-options-display'));
                        }
                    } else {
                        displayServerError();
                    }
                });
            }
        });

    /**
     * @listener
     * @desc Listens for the click event on the post code change and delivery
     *       back buttons to bring back the post code form
     */
    $miniCartContent
        .off('click.delivery')
        .on('click.delivery', '.change-postal-code, .delivery-back', function (e) {
            e.preventDefault();
            var $link = $(this);
            $.ajax({
                type: 'POST',
                url: $link.attr('href'),
                success: function (response) {
                    if (typeof response === 'object' &&
                        typeof response.data !== 'undefined') {
                        refreshMiniCartTemplate($miniCartContent
                            .find('.mini-cart-delivery-states'));

                        window.User.zip = null;
                        $('.tile-add-to-cart').addClass(
                                'delivery-add-to-cart')
                            .removeClass(
                                'tile-add-to-cart');
                        $('.add-to-cart')
                            .addClass(
                                'details-delivery-add-to-cart')
                            .removeClass('add-to-cart');

                        // Refresh the homepage delivery
                        // options if they are present
                        if ($('#delivery-options-display').length) {
                            refreshHomeDeliveryTemplate($('#delivery-options-display'));
                        }
                        window.location.reload();
                    } else {
                        displayServerError();
                    }
                },
                error: function () {
                    displayServerError();
                }
            });
        });
    $miniCartContent.on('submit', '.postcode-validation-form.delivery-minicart-container form', function () {
        // If you update the postcode in the minicart form, update the minicart correctly
        if ($('#minicart-postcode-input').val()){
            refreshProductPrices(true);
        }
    });

    /**
     * @listener
     * @desc Listens for the click event on the rebook button
     */
    $miniCartContent.on('submit','.rebook-slot', function (e) {
        e.preventDefault();
        const $form = $(this);

        if ($form.valid()) {
            const jqXHR = $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data: $form.serialize()
            });

            jqXHR.done(function (response) {
                if (typeof response === 'object' &&
                    typeof response.data !== 'undefined') {
                    refreshMiniCartTemplate($miniCartContent
                        .find('.mini-cart-delivery-states'));

                    // Refresh the homepage delivery
                    // options if they are present
                    if ($('#delivery-options-display').length) {
                        refreshHomeDeliveryTemplate($('#delivery-options-display'));
                    }
                } else {
                    showDeliverySlotUnavailable(
                        $miniCartContent
                        .find('.mini-cart-delivery-states'),
                        window.Urls.miniCartDeliveryStates);

                    if ($('#delivery-options-display').length) {
                        showDeliverySlotUnavailable(
                            $('#delivery-options-display'),
                            window.Urls.headerPostcodeValidation);
                    }
                }
            });

            jqXHR.fail(function () {
                displayServerError();
            });
        }
    });
}

/**
 * @function
 * @desc Sets up the cart and review page delivery functionality
 * @returns
 */
function initializeCart() {
    const $cartSection = $('.delivery-summary .card-body');

    /**
     * @listener
     * @desc Listens for the click event on the rebook button
     */
    $cartSection.find('.rebook-slot')
        .on('submit',function (e) {
            e.preventDefault();
            const $form = $(this);

            if ($form.valid()) {
                const jqXHR = $.ajax({
                    type: 'POST',
                    url: $form.attr('action'),
                    data: $form.serialize()
                });

                jqXHR.done(function (response) {
                    if (typeof response === 'object' &&
                        typeof response.data !== 'undefined') {
                        refreshCartTemplate($cartSection);
                    } else {
                        showDeliverySlotUnavailable($cartSection,
                            window.Urls.cartDeliveryStates);

                        if ($('#delivery-options-display').length) {
                            showDeliverySlotUnavailable(
                                $('#delivery-options-display'),
                                window.Urls.headerPostcodeValidation);
                        }
                    }
                });

                jqXHR.fail(function () {
                    displayServerError();
                });
            }
        });
}

/**
 * @function
 * @desc Sets up the cart and review page delivery functionality
 * @returns
 */
function initializeCheckout() {
    const $checkoutSection = $('.checkout-order-totals-delivery');

    /**
     * @listener
     * @desc Listens for the click event on the rebook button
     */
    $checkoutSection.find('.rebook-slot')
        .on('submit', function (e) {
            e.preventDefault();
            const $form = $(this);

            if ($form.valid()) {
                const jqXHR = $.ajax({
                    type: 'POST',
                    url: $form.attr('action'),
                    data: $form.serialize()
                });

                jqXHR.done(function (response) {
                    if (typeof response === 'object' &&
                        typeof response.data !== 'undefined') {
                        refreshCheckoutTemplate($checkoutSection);
                    } else {
                        showDeliverySlotUnavailable($checkoutSection,
                            window.Urls.checkoutDeliveryStates);
                    }
                });

                jqXHR.fail(function () {
                    displayServerError();
                });
            }
        });
}

/**
 * @function
 * @desc Initializes any features in the DOM
 * @returns
 */
function initializeDocument() {
    initExpirationCountdown();
    initOrderEditExpirationCountdown();
    initMinicartOrderEditExpirationCountdown();
    initMobileMenuExpirationCountdown();
}

/**
 * @function
 * @desc Initializes the expiration countdown. Used to init the count down on
 *       document load and when an ajax call completes
 * @returns
 */
function initExpirationCountdown() {
    const expirationDateTime = $('.delivery-expiration-message').attr('data-expiration-date');
    if (typeof expirationDateTime !== 'undefined' &&
        expirationDateTime !== 'null') {
        setExpirationCountdown(expirationDateTime);
    }
}

/**
 * @function
 * @desc Initializes the expiration countdown on orders page and the home page.
 *       Used to init the count down on document load and when an ajax call
 *       completes
 * @returns
 */
function initOrderEditExpirationCountdown() {
    var $orderDeliveryExpirationMessage = $('#order-delivery-expiration-message');
    if (setOrderEditExpirationCountdown($orderDeliveryExpirationMessage) === null) {
        refreshOrderEditCoundown();
    }
}

/**
 * @function
 * @desc Initializes the expiration countdown in the minicart. Used to init the
 *       count down on document load and when an ajax call completes
 * @returns
 */
function initMinicartOrderEditExpirationCountdown() {
    var $orderDeliveryExpirationMessage = $('#mini-cart .delivery-expiration-message');
    setOrderEditExpirationCountdown($orderDeliveryExpirationMessage);
}

/**
 * @function
 * @desc Initializes the expiration countdown in the mobile menu. Used to init
 *       the count down on document load and when an ajax call completes
 */
function initMobileMenuExpirationCountdown() {
    var $orderDeliveryExpirationMessage = $('.left-nav-minicart').find('.delivery-expiration-message');
    setOrderEditExpirationCountdown($orderDeliveryExpirationMessage);
}

/**
 * @function
 * @desc Sets up the delivery slot expiration countdown
 * @param {String} dateTime - Date string to use in the expiration timer
 * @param {Object} $orderDeliveryExpirationMessage - The jQuery element with the expiration message
 * @returns
 */
function setOrderEditExpirationCountdown($orderDeliveryExpirationMessage) {
    const expirationDateTime = $orderDeliveryExpirationMessage.attr('data-expiration-date');
    if (typeof expirationDateTime === 'undefined' ||
        expirationDateTime === 'null') {
        return null;
    }

    const expirationDate = new Date(expirationDateTime).getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {

        // Get todays date and time
        const now = new Date().getTime();

        // Find the distance between now an the count down date
        var distance = expirationDate - now;

        // If the count down is finished, write some text
        if (distance < 0) {
            clearInterval(x);
            refreshOrderEditCoundown();
        } else {
            // Time calculations for hours, minutes and seconds
            var hours, minutes, seconds;
            seconds = Math.floor(distance / 1000);
            minutes = Math.floor(seconds / 60);
            seconds = seconds % 60;
            hours = Math.floor(minutes / 60);
            minutes = minutes % 60;
            seconds = seconds < 10 ? '0' + String(seconds) : String(seconds);
            minutes = minutes < 10 ? '0' + String(minutes) : String(minutes);

            // Display the result in the expiration message section
            $orderDeliveryExpirationMessage.find('.hours').text(hours);
            $orderDeliveryExpirationMessage.find('.minutes').text(minutes);
            $orderDeliveryExpirationMessage.find('.seconds').text(seconds);
        }
    }, 1000);
    return x;
}

/**
 * @function
 * @desc Sets up the delivery slot expiration countdown
 * @param {String}
 *            dateTime - Date string to use in the expiration timer
 * @returns
 */
function setExpirationCountdown(dateTime) {
    const expirationDate = new Date(dateTime).getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {

        // Get todays date and time
        const now = new Date().getTime();

        // Find the distance between now an the count down date
        var distance = expirationDate - now;

        // If the count down is finished, write some text
        if (distance < 0) {
            clearInterval(x);
            var $container = $('#delivery-options-display');
            if ($container.length) {
                refreshHomeDeliveryTemplate($container);
            }
        } else {
            // Time calculations for minutes and seconds
            var minutes = Math.floor((distance % (1000 * 60 * 60)) /
                (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the expiration message section
            $('.delivery-expiration-message .minutes').text(minutes);
            $('.delivery-expiration-message .seconds').text(seconds);
        }
    }, 1000);
}

function checkPostcode($form) {
    if (typeof $form !== 'undefined' && $form.valid()) {
        const formData = $form.serialize();
        const jqXHR = $.ajax({
            type: 'POST',
            url: $form.attr('action'),
            data: formData
        });

        jqXHR.done(function (response) {
            if (response.data && response.data.code === 'success') {
                $('.delivery-add-to-cart').addClass('tile-add-to-cart')
                    .removeClass('delivery-add-to-cart');
                $('.details-delivery-add-to-cart').addClass('add-to-cart')
                    .removeClass('details-delivery-add-to-cart');
            }
        });

        jqXHR.fail(function () {
            displayServerError();
        });

        return jqXHR;
    }
}

/**
 * @function
 * @desc Refreshes the home page delivery template
 * @param {jQuery}
 *            $container - jQuery object of the home page delivery section
 * @returns
 */
function refreshHomeDeliveryTemplate($container) {
    const jqXHR = ajax.load({
        url: window.Urls.headerPostcodeValidation,
        target: $container
    });

    if (typeof jqXHR !== 'undefined') {
        jqXHR.done(function () {
            delivery.init();
        });
    }

    return jqXHR;
}

function refreshOrderEditCoundown() {
    if ($('.edit-not-expired').length > 0) {
        $('.edit-not-expired').hide();
    }

    $('.edit-expired').show();
}

/**
 * @function
 * @desc Refreshes the home page delivery template with an unavailable message
 * @param {jQuery}
 *            $container - jQuery object of the home page delivery section
 * @returns
 */
function showDeliverySlotUnavailable($container, resourceLocation) {
    var url = util.appendParamToURL(resourceLocation, Resources.SLOT_NOT_AVAILABLE_STATUS, 'true');
    const jqXHR = ajax.load({
        url: url,
        target: $container
    });

    if (typeof jqXHR !== 'undefined') {
        jqXHR.done(function () {
            delivery.init();
        });
    }

    return jqXHR;
}

/**
 * @function
 * @desc Refreshes the drop down delivery template
 * @param {jQuery}
 *            $dropdown - jQuery object of the drop down to refresh
 * @returns
 */
function refreshDropDownDeliveryTemplate($dropdown) {
    const jqXHR = ajax.load({
        url: Urls.dropdownPostcodeValidation,
        target: $dropdown
    });

    if (typeof jqXHR !== 'undefined') {
        jqXHR.done(function () {
            initializeDeliveryDropdown($dropdown);
            initExpirationCountdown();
        });
    }

    return jqXHR;
}

/**
 * @function
 * @desc Refreshes the mini cart delivery template
 * @param {jQuery}
 *            $miniCartDelivery - jQuery object of the mini cart section to
 *            refresh
 * @returns
 */
function refreshMiniCartTemplate($miniCartDelivery) {
    const jqXHR = ajax.load({
        url: Urls.miniCartDeliveryStates,
        target: $miniCartDelivery
    });

    if (typeof jqXHR !== 'undefined') {
        jqXHR.done(function () {
            initializeMiniCart();
            initExpirationCountdown();
            // $('body').on('click', '#minicart-postcode-input', this.maskApplier);
            $('#minicart-postcode-input').mask('00000-000');
        });
    }

    return jqXHR;
}

/**
 * @function
 * @desc Refreshes the cart delivery template
 * @param {jQuery}
 *            $contentSection - jQuery object of the delivery section to refresh
 * @returns
 */
function refreshCartTemplate($deliverySection) {
    const jqXHR = ajax.load({
        url: Urls.cartDeliveryStates,
        target: $deliverySection
    });

    if (typeof jqXHR !== 'undefined') {
        jqXHR.done(function () {
            initializeCart();
        });
    }

    return jqXHR;
}

/**
 * @function
 * @desc Refreshes the checkout delivery template
 * @param {jQuery}
 *            $contentSection - jQuery object of the delivery section to refresh
 * @returns
 */
function refreshCheckoutTemplate($deliverySection) {
    const jqXHR = ajax.load({
        url: Urls.checkoutDeliveryStates,
        target: $deliverySection
    });

    if (typeof jqXHR !== 'undefined') {
        jqXHR.done(function () {
            initializeCheckout();
        });
    }

    return jqXHR;
}

/**
 * @function
 * @desc Refreshes the page if it is one that has product prices displayed
 * @returns
 */
function refreshProductPrices(showProgress) {

    var refreshPage = false;
    var elementsToRefresh = [];
    //TODO: will not work until minicart customizations are added
    // Check for product tiles
    if ($('.grid-tile').length > 0) {
        refreshPage = true;
        elementsToRefresh.push('.grid-tile')
        // Check for a PDP form
    }
    if ($('.pdpForm').length > 0) {
        refreshPage = true;
        elementsToRefresh.push('.pdpForm')
    }
    if ($('#pdpMain').length > 0) {
        refreshPage = true;
        elementsToRefresh.push('#pdpMain')
    }
        // Check for the cart page
    if ($('.cart-action-checkout').length > 0) {
        refreshPage = true;
        elementsToRefresh.push('.cart-action-checkout')
        // Check for the book a slot page
    }
    if ($('.delivery-info').length > 0 && $('.delivery-confirmation-info').length === 0) {
        refreshPage = true;
        //for not refresh info address when cep is not registered or no has address
        if(!$('.js-hasNoCepRegistered')) elementsToRefresh.push('.delivery-info');
        elementsToRefresh.push('.delivery-confirmation-info');
    }
    // Recalculate the cart and reload the minicart
    if (showProgress) {
        progress.show();
        $.ajax({
            url: Urls.miniCart,
            type:  'GET',
            dataType: 'html',
            success: function(data) {
                minicart.show(data, !$('.mini-cart-empty').length);
            }
        })
    }
    if (refreshPage) {
        const url = new URL(window.location.href);
        // Although it is possible to defer
        // the nature of the request, an param differs the route cache.
        url.searchParams.append('ajax', true);

        $.ajax({
            url: url.toString(),
            type:  'GET',
            dataType: 'html',
            beforeSend: $.spinner().start(),
            success: function(data) {
                var $response = $(data);
                for (var x = 0; x < elementsToRefresh.length; x++) {
                    // if it's grid-tiles you'll need to loop over them
                    if (elementsToRefresh[x] === '.grid-tile') {
                        var responseTiles = $response.find('.grid-tile');
                        var oldTiles = $('.grid-tile')
                        if (responseTiles.length === oldTiles.length) {
                            for (var j = 0; j < oldTiles.length; j++) {
                                $(oldTiles[j]).html(responseTiles[j].innerHTML);
                            }
                        }
                    } else {
                        // Just replace the single element
                        var newHTML = $response.find(elementsToRefresh[x]).html();
                        $(elementsToRefresh[x]).html(newHTML);
                    }

                    $('body').trigger('apply:slick');
                }
                //hide new address container if existing address selected
                if($('.delivery-address-info').length) $('.add-address-container').slideUp();

                $.spinner().stop();
            },
            error: function (err) {
                console.log('error:', err);
                $.spinner().stop();
            }
        })
    }
}

/**
 * @function
 * @desc Retrieves the delivery drop down and displays it on the page
 * @param $dropdown
 * @returns
 */
function showAddToCartDropDown($dropdown) {
    if ($dropdown.children().length > 0) {
        $dropdown.empty();
    } else {
        $('.delivery-details').each(function () {
            $(this).empty();
        });

        const jqXHR = ajax.load({
            url: Urls.dropdownPostcodeValidation,
            target: $dropdown
        });

        jqXHR.done(function (response) {
            if (response) {
                initializeDeliveryDropdown($dropdown);

                if (!$('.promo-modal').is(':visible') &&
                    !$dropdown.parents('.slick-slider').length) {
                    // $('.modal-background').show();
                }

                $dropdown.show();

                $(window).on('resize.delivery', function () { // PDP delivery Modal
                    if ($dropdown.parents('.product-actions').length) {
                        if($dropdown.parents('.product-actions').find('.details-delivery-add-to-cart').length) {
                            if (util.mediaBreakpointUp('md')) {
                                $dropdown.position({
                                    my: 'right bottom',
                                    at: 'center+30px top-12px',
                                    of: $('.details-delivery-add-to-cart')
                                });
                            } else {
                                $dropdown.position({
                                    my: 'right bottom',
                                    at: 'right top-12px',
                                    of: $('.details-delivery-add-to-cart')
                                });
                            }
                        }
                    } else if ($dropdown.parents('.tile-actions').length) {
                        if($dropdown.parents('.tile-actions').find('.delivery-add-to-cart').length) {
                            if (util.mediaBreakpointUp('sm')) {
                                if($dropdown.hasClass('flipped')) {
                                    $dropdown.removeClass('flipped');
                                }

                                $dropdown.position({
                                    my: 'left bottom',
                                    at: 'center top-12px',
                                    of: $dropdown.parents('.tile-actions').find('.delivery-add-to-cart'),
                                    using: function (position, data) {
                                        if (data.horizontal === 'right') {
                                            $(this).addClass('flipped');
                                        } else {
                                            $(this).removeClass('flipped');
                                        }

                                        if (data.vertical === 'top') {
                                            $(this).addClass('flipped-bottom');
                                        } else {
                                            $(this).removeClass('flipped-bottom');
                                        }

                                        $(this).css(position);
                                    }
                                });
                            } else {
                                $dropdown.addClass('flipped');
                                $dropdown.position({
                                    my: 'right+5px bottom',
                                    at: 'right; top-12px',
                                    of: $dropdown.parents('.tile-actions').find('.delivery-add-to-cart'),
                                    using: function (position, data) {
                                        if (data.vertical === 'top') {
                                            $(this).addClass('flipped-bottom');
                                        } else {
                                            $(this).removeClass('flipped-bottom');
                                        }

                                        $(this).css(position);
                                    }
                                });
                            }
                        }
                    }
                });

                $(window).trigger('resize', [{deliveryFile: true}]);
            }
        });
    }
}

/**
 * @function
 * @desc Shows a server error in a dialog box
 * @returns
 */
function displayServerError() {
    const $dialogTarget = $('#dialog-container');

    dialog.open({
        content: '<h1>' + Resources.SERVER_ERROR + '</h1>',
        target: $dialogTarget,
        options: {
            title: Resources.ERROR
        }
    });
}

module.exports = delivery;
